import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import HowToHelp from "../components/shared/how-to-help"
import InnerBanner from "../components/shared/inner-banner"
import Sections from "../components/terms-and-conditions/sections"
import banner from "../images/available-dogs-banner-bg.jpg"

const TermsAndConditionsPage = () => {

    return <Layout colored_header={true}>
        <Seo title="Terms and Conditions" />
        <InnerBanner theme="white" image={banner} title="Terms and Conditions" />

        <Sections.Section1/>


        <HowToHelp />
    </Layout>
}

export default TermsAndConditionsPage